<template>
  <div class="noticeContainer">
    <div class="notice">
      <div class="itemArea bannerItemArea">
        <div class="banner">
          <el-carousel :autoplay="false" ref="bannerRef" height="400px" indicator-position="none" @change="changeBanner">
            <el-carousel-item v-for="(item,index) in bannerList" :key="index">
              <el-image class="bannerImg" @click="toPage(item)" fit="cover" :src="item.src">
                <template #error>
                  <div class="err-slot">
                    <i class="el-icon-picture-outline"></i>
                  </div>
                </template>
                <template #placeholder>
                  <div class="err-slot">
                    加载中...
                  </div>
                </template>
              </el-image>
            </el-carousel-item>
          </el-carousel>
          <div class="indicator">
            <el-tooltip placement="bottom" trigger="hover" :content="bannerList[indicatorIndex].title">
              <div class="bannerTitle">{{bannerList[indicatorIndex].title}}</div>
            </el-tooltip>
            <div class="bannerIndicatorList">
              <div class="indicatorIcon" @click="toBannerImg(index)" :class="{active:indicatorIndex == index}" v-for="(item,index) in bannerList.length" :key="index"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="itemArea">
        <el-card>
          <template #header>
            <div class="header">
              <div class="headerTitle">最新消息</div>
              <el-button type="text" @click="routerToNewsList">更多+</el-button>
            </div>
          </template>
          <template v-for="(item,index) in newList" :key="index">
            <div class="noticeListItem">
              <span class="label" @click="routerToNewsDetail(item)">{{item.title}}</span>
              <span class="date pcShow">{{item.create_at}}</span>
              <span class="date phoneShow">{{item.create_at_min}}</span>
            </div>
          </template>
        </el-card>
      </div>
      <div class="itemArea studyItemArea">
        <el-card>
          <template #header>
            <div class="header">
              <div class="headerTitle">{{year}}年培训安排</div>
              <el-button type="text" @click="openWX">了解更多</el-button>
            </div>
          </template>
          <div class="studyGrid">
            <el-row type="flex" justify="space-between" v-for="(item,index) in studyList.length / 3" :key="index">
              <el-col :span="8" v-for="(aitem,aindex) in studyList.slice((item-1)*3,item*3)" :key="aindex">
                <div class="studyGridItem">
                  <div class="title">{{aitem.month}}月</div>
                  <div class="label" v-for="(bitem,bindex) in aitem.contentList" :key="bindex">
                    <div style="text-align:left">{{bitem}}</div>
                  </div>
                </div>
              </el-col>
            </el-row>
          </div>
        </el-card>
      </div>
      <div class="itemArea">
        <el-card>
          <template #header>
            <div class="header">
              <div class="headerTitle">系统入口</div>
              <el-button type="text"></el-button>
            </div>
          </template>
          <div class="systemGrid">
            <el-row class="pcShow" type="flex" justify="space-between" v-for="(item,index) in Math.ceil(systemList.length / 4)" :key="index">
              <el-col :span="8" v-for="(aitem,aindex) in systemList.slice((item-1)*4,item*4)" :key="aindex">
                <div class="systemGridItem">
                  <div class="labelList" v-if="aitem.contentList.length > 0">
                    <div class="label" v-for="(bitem,bindex) in aitem.contentList" :key="bindex" @click="openSystem(bitem.url)">{{bitem.label}}</div>
                  </div>
                  <div v-else style="text-align:center">
                    <img style="width:100%" :src="aitem.img" alt="">
                    <div>{{aitem.imgLabel}}</div>
                  </div>
                  <div class="title" v-html="aitem.title"></div>
                </div>
              </el-col>
            </el-row>
            <el-row class="phoneShow" type="flex" justify="space-between" v-for="(item,index) in Math.ceil(systemList.length / 2)" :key="index">
              <el-col :span="12" v-for="(aitem,aindex) in systemList.slice((item-1)*2,item*2)" :key="aindex">
                <div class="systemGridItem">
                  <div class="labelList">
                    <div class="label" v-for="(bitem,bindex) in aitem.contentList" :key="bindex" @click="openSystem(bitem.url)">{{bitem.label}}</div>
                  </div>
                  <div class="title" v-html="aitem.title"></div>
                </div>
              </el-col>
            </el-row>
          </div>
        </el-card>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { ref, defineComponent, onMounted } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import moment from "moment";
import { study_getContent } from "@axios/index";
export default defineComponent({
  name: "notice",
  setup(props, content) {
    const store = useStore();
    const router = useRouter();
    // const year = moment().format("YYYY");
    const year = 2024
    const bannerRef = ref(null);
    let bannerList = [
      // {
      //   src: `${process.env.VUE_APP_FILE_HOST}/file/banner/关于河南省预拌混凝土企业试验实操视频观看的通知.png`,
      //   url: "https://mp.weixin.qq.com/s/JRTba44wG4k0Jp0tSwfOgA",
      //   title: "关于河南省预拌混凝土企业试验实操视频观看的通知",
      // },
      {
        src: `${process.env.VUE_APP_FILE_HOST}/file/banner/关于做好2024年度河南省工程系列建筑专业副高级职称评审和专业技术人员职业资格副高级职称考核认定申报工作的通知.png`,
        url: "https://mp.weixin.qq.com/s/lW_t7TkRY6-q9GLnblqVdg",
        title: "关于做好2024年度河南省工程系列建筑专业副高级职称评审和专业技术人员职业资格副高级职称考核认定申报工作的通知",
      },
      {
        src: `${process.env.VUE_APP_FILE_HOST}/file/banner/《河南省预拌混凝土质量管理标准》宣贯会在商丘市成功举办.jpg`,
        url: "http://www.hnjsgcjs.cn/#/news/detail?id=51",
        title: "《河南省预拌混凝土质量管理标准》宣贯会在商丘市成功举办",
      },
      {
        src: `${process.env.VUE_APP_FILE_HOST}/file/banner/扎实推进技能河南建设 奏响高质量发展强音.jpeg`,
        url: "http://www.hnjsgcjs.cn/#/news/detail?id=49",
        title: "扎实推进技能河南建设 奏响高质量发展强音",
      },
    ];
    let studyList = ref([
      { month: 1, contentList: [] },
      { month: 2, contentList: [] },
      { month: 3, contentList: [] },
      { month: 4, contentList: ["预拌混凝土质量管理标准宣贯-信阳站","预拌混凝土质量管理标准宣贯-开封站"] },
      { month: 5, contentList: ["预拌混凝土质量管理标准宣贯-洛阳站","预拌混凝土质量管理标准宣贯-许昌站",'河南省建设工程检测员（建筑材料及构配件）','河南省建设工程检测员（市政工程材料）'] },
      { month: 6, contentList: ["河南省建设工程检测员（主体结构及装饰装修）", "河南省预拌混凝土企业试验员（混凝土）培训"] },
      { month: 7, contentList: [] },
      { month: 8, contentList: [] },
      { month: 9, contentList: [] },
      { month: 10, contentList: [] },
      { month: 11, contentList: [] },
      { month: 12, contentList: [] },
    ]);
    let newList = ref([]);
    let systemList = [
      {
        title: "河南省<br/>预拌混凝土<br/>信息数据平台",
        contentList: [
          // {
          //   label: "检测用户端",
          //   url: "https://hnsyjzx.cn/testin/#/loginIndex",
          // },
          { label: "企业端", url: "https://hnsyjzx.cn/busin/#/loginIndex" },
          { label: "管理端", url: "https://hnsyjzx.cn/admin/#/loginIndex" },
        ],
      },
      {
        title: "河南省<br/>预拌混凝土<br/>生产企业<br/>质量保证体系<br/>动态评价",
        contentList: [
          { label: "管理单位", url: "https://dongtaipingjia.com/admin/index.html" },
          { label: "预拌混凝土企业", url: "https://dongtaipingjia.com/busin/index.html" },
        ],
      },
      {
        title: "郑州市<br/>建设工程质量<br/>检测智慧平台",
        contentList: [
          { label: "检测企业端", url: "https://hnsyjzx.cn/testin/#/login" },
          { label: "商混企业端", url: "https://hnsyjzx.cn/busin/#/login" },
          { label: "管理端", url: "https://hnsyjzx.cn/admin/#/login" },
        ],
      },
      // {
      //   title: "河南省豫建中心<br/>“云课程”",
      //   img:require('@assets/img/Code/study_code.jpg'),
      //   imgLabel:'打开微信“扫一扫”，扫描二维码开始学习。',
      //   contentList: [
      //   ],
      // },
      {
        title: "培训记录查询",
        img:require('@assets/img/Code/study_search.png'),
        imgLabel:'打开微信“扫一扫”，扫描二维码开始学习。',
        contentList: [
        ],
      },
      {
        title: "郑州市室内环境<br/>检测管理系统",
        contentList: [
          { label: "企业端", url: "https://hnyj.hnsyjzx.cn" },
          { label: "管理端", url: "https://jczzhj.hnsyjzx.cn/pc/index.html" },
        ],
      },
      // {
      //   title: "河南省预拌混凝土<br/>生产企业试验<br/>能力比对系统",
      //   contentList: [
      //     { label: "企业端", url: "https://neng.hnsybsh.com/company" },
      //     { label: "管理端", url: "https://neng.hnsybsh.com" },
      //   ],
      // },
      // {
      //   title: "河南省建设工程质量<br/>检测机构<br/>能力比对系统",
      //   contentList: [
      //     { label: "企业端", url: "https://jzbd.hnsyjzx.cn/phone" },
      //     { label: "管理端", url: "https://jzbd.hnsyjzx.cn" },
      //   ],
      // },
      
      {
        title: "奖项查询系统",
        contentList: [
          { label: "查询系统", url: "https://henan.hnsyjzx.cn/admin/#/search" },
          { label: "平台", url: "https://professional.hnsyjzx.cn/index.html#/professor/login" },
        ],
      },
      {
        title: "答辩系统",
        contentList: [
          // { label: "专家端", url: "https://professional.hnsyjzx.cn/index.html#/professor/login" },
          { label: "答辩端", url: "https://professional.hnsyjzx.cn/index.html#/user/login" },
        ],
      },
      {
        title: "河南省豫建中心<br/>报名系统",
        contentList: [
          {
            label: "新进人员报名",
            url: "https://yjzx.hnsyjzx.cn/subInfo/new.html",
          },
          {
            label: "继续教育报名",
            url: "https://yjzx.hnsyjzx.cn/subInfo/continue.html",
          },
          {
            label: "技术提升班报名",
            url: "https://yjzx.hnsyjzx.cn/subInfo/up.html",
          },
          {
            label: "检测人员报名",
            url: "https://yjzx.hnsyjzx.cn/subInfo/test.html",
          },
          { label: "管理端", url: "https://yjzx.hnsyjzx.cn/memberAdmin" },
        ],
      },
      // {
      //   title: "答辩系统答辩端",
      //   contentList: [
      //     { label: "答辩端", url: "https://professional.hnsyjzx.cn/index.html#/user/login" },
      //   ],
      // },
    ];
    let indicatorIndex = ref(0);

    /**轮播转动事件 */
    function changeBanner(i) {
      indicatorIndex.value = i;
    }

    /**手动跳转轮播 */
    function toBannerImg(i) {
      bannerRef.value.setActiveItem(i);
    }

    /**跳转公众号 */
    function openWX() {
      window.open(
        "https://mp.weixin.qq.com/s/13fHevyEehhveapyc2pC7Q",
        "_blank"
      );
    }

    /**跳转系统入口 */
    function openSystem(url) {
      window.open(url, "_blank");
    }

    function toPage(item) {
      if(item.url){
        window.open(item.url, "_blank");
      }
    }

    function routerToNewsDetail(item) {
      if (item.href) {
        window.open(item.href, "_blank");
      } else {
        router.push({ path: `/news/detail`, query: { id: item.id } });
      }
    }

    function routerToNewsList() {
      router.push({ path: `/news`, query: { page: 1, key: "" } });
    }

    async function getNews() {
      const onlineNews = await study_getContent({});
      const wxNews = store.state.wxNews;
      const news = [...onlineNews, ...wxNews];
      newList.value = news
        .sort((a, b) => new Date(b["created_at"]).valueOf() - new Date(a["created_at"]).valueOf())
        .slice(0, 9)
        .map(item => {
          return {
            ...item,
            create_at: `[${moment(new Date(item.created_at)).format(
              "YYYY年MM月DD日"
            )}]`,
            create_at_min: moment(new Date(item.created_at)).format("YY/MM/DD"),
          };
        });
    }
    getNews();
    return {
      bannerList,
      newList,
      studyList,
      systemList,
      year,
      openSystem,
      openWX,
      routerToNewsDetail,
      routerToNewsList,
      router,
      changeBanner,
      indicatorIndex,
      toBannerImg,
      bannerRef,
      toPage,
    };
  },
});
</script>
<style lang="less" scoped>
@itemAreaMaxWidth: 580px;
@itemAreaHeight: 300px;
@itemSystemBorderColor: #eae7e7;
.noticeContainer {
  width: 100%;
  background: white;
}
.notice {
  width: @mainWidth;
  margin: 0 auto;
  max-width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: stretch;
  overflow: hidden;
  .itemArea {
    @headerHeight: 40px;
    margin: 25px 0;
    position: relative;
    display: inline-block;
    width: @itemAreaMaxWidth;
    min-height: @itemAreaHeight;
    max-width: @mainWidth;
    > * {
      height: inherit;
    }
    /deep/ .el-card__header {
      background: #edf6ff;
      color: @mainColor;
      padding: 0 5px 0 0;
      overflow: hidden;
      .headerTitle {
        background: #2f79c3;
        width: 160px;
        text-align: center;
        height: @headerHeight;
        line-height: @headerHeight;
        padding: 0 20px;
        color: white;
      }
    }
    /deep/ .el-card__body {
      background: #f7f8fa;
      padding: 0;
    }
    /**轮播 */
    .banner {
      /deep/ .el-carousel {
        overflow: hidden;
        z-index: 0;
        .bannerImg {
          width: inherit;
          max-width: inherit;
          height: inherit;
          text-align: center;
          cursor: pointer;
          .err-slot {
            height: inherit;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 40px;
            border: 1px solid grey;
          }
        }
      }
      .indicator {
        z-index: 0;
        width: 100%;
        height: 43px;
        position: absolute;
        bottom: 0;
        left: 0;
        background: #4c4c4c94;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 10px;
        .bannerTitle {
          flex: auto;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          color: white;
        }
        .bannerIndicatorList {
          flex: 1;
          display: flex;
          justify-content: flex-end;
          .indicatorIcon {
            width: 20px;
            height: 20px;
            border: 2px solid white;
            border-radius: 50%;
            cursor: pointer;
            &:not(:last-child) {
              margin-right: 5px;
            }
            &.active {
              background: @orangeColor;
            }
          }
        }
      }
    }

    /**新闻列表 */
    /deep/ .el-card {
      height: 100%;
      box-shadow: none;
      .el-card__header {
        max-height: @headerHeight;
        min-height: @headerHeight;
      }
      .el-card__body {
        height: calc(100% - @headerHeight);
        .noticeListItem {
          display: flex;
          justify-content: space-between;
          width: 100%;
          padding: 9px 15px;
          padding-left: 30px;
          position: relative;
          &::before {
            content: " ";
            display: block;
            background: @orangeColor;
            width: 17px;
            height: 10px;
            border-radius: 50%;
            position: absolute;
            left: 15px;
            top: 50%;
            transform: translate(-50%, -50%);
            background: url("~@assets/img/tip-icon.png");
          }
          .label {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            flex: 1;
            &:hover {
              color: @mainColor;
              cursor: pointer;
              text-decoration: underline;
            }
          }
          .date {
            white-space: nowrap;
            font-size: 14px;
          }
        }
      }
      .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .noticeListHeaderImg {
          height: 40px;
          width: 150px;
        }
      }
    }
  }
  /**培训日历 */
  .studyItemArea {
    /deep/ .el-card__header {
      // background:#ef2121ad;
      // .headerTitle{
      //   background-color: #259e2a;
      // }
      // .el-button--text{
      //   color: white;
      // }
    }
    /deep/ .el-card__body {
      background: white;
      border: 1px solid #ee0a24;
      border-top: none;
      .studyGrid {
        .el-row {
          &:not(:last-child) {
            .studyGridItem {
              border-bottom: 1px solid #ee0a24;
            }
          }
          .el-col {
            &:not(:last-child) {
              border-right: 1px solid #ee0a24;
            }
            flex: 1;
            .studyGridItem {
              width: 100%;
              height: 100%;
              min-height: 80px;
              padding: 5px;
              &:hover {
                background: #ee0a24;
                .title {
                  color: white;
                }
                .label {
                  color: white;
                  &::before {
                    background: #5fd063;
                    box-shadow: 0 1px 4px #5fd063;
                  }
                }
              }
              .title {
                color: @mainColor;
              }
              .label {
                text-align: right;
                font-size: 14px;
                flex: 1;
                position: relative;
                padding-left: 20px;
                &:before {
                  content: " ";
                  width: 10px;
                  height: 10px;
                  border-radius: 50%;
                  background: #ee0a24;
                  box-shadow: 0 1px 4px #ee0a24;
                  position: absolute;
                  left: 4px;
                  top: 50%;
                  transform: translateY(-50%);
                }
              }
              .date {
                white-space: nowrap;
                font-size: 14px;
              }
            }
          }
        }
      }
    }
  }

  /**系统入口 */
  .systemGrid {
    height: 100%;
    /deep/ .el-row {
      height: 50%;
      &:not(:last-child) {
        .systemGridItem {
          border-bottom: 0.5px solid @itemSystemBorderColor;
        }
      }
      .el-col {
        flex: 1;
        .systemGridItem {
          border-left: none !important;
          border-right: 0.5px solid @itemSystemBorderColor;
        }
        &:last-child {
          .systemGridItem {
            border-right: none !important;
          }
        }
        .systemGridItem {
          width: 100%;
          height: 165px;
          min-height: 80px;
          position: relative;
          overflow: hidden;
          background: #f7f6f6;
          &:hover {
            .title {
              top: -100%;
            }
            .label {
              cursor: pointer;
            }
          }
          .labelList {
            position: absolute;
            top: 50%;
            left: 0;
            width: 100%;
            transform: translate(0, -50%);
            .label {
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
              text-align: center;
              flex: 1;
              padding: 3px 0;
              &:hover {
                color: @mainColor;
              }
            }
          }
          .title {
            // background: linear-gradient(#5ea2e6, #0163a6);
            background: url("~@assets/img/system_cover.png");
            background-position: right top;
            color: white;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all 0.5s;
            padding: 10px;
            text-align: center;
          }

          .date {
            white-space: nowrap;
            font-size: 14px;
          }
        }
      }
    }
  }

  .phoneShow {
    display: none;
  }
  .pcShow {
    display: flex;
  }

  /**窄屏自动居中 */
  @media (max-width: 600px) {
    @bannerphoneheight:42.8vw !important;
    .noticeContainer {
      width: 100vw;
    }
    .itemArea {
      width: inherit;
      max-width: inherit;
      margin: 10px auto;
      margin-top: inherit;
      &.bannerItemArea {
        height: @bannerphoneheight;
        min-height: 0;
        /deep/ .el-carousel__container {
          height: @bannerphoneheight;
          .bannerImg {
            height: @bannerphoneheight;
          }
        }
      }
    }
    .studyGridItem {
      .label {
        font-size: 13px;
      }
    }
    .systemGrid {
      height: 800px;
      /deep/ .el-row {
        height: 25%;
      }
      .label {
        white-space: initial !important;
        text-overflow: initial !important;
        overflow: initial !important;
      }
    }

    .phoneShow {
      display: flex !important;
    }
    .pcShow {
      display: none !important;
    }
  }
}
</style>